<template>
  <section class="head">
    <div class="bg" :style="{ backgroundImage: 'url(' + topImg + ')' }"></div>
    <div class="content" v-if="Object.keys(dataInfo).length > 0">
      <div class="cover">
        <img :src="dataInfo.coverImg" alt="" />
      </div>
      <div class="text">
        <h1>{{ dataInfo.courseName }}</h1>
        <template v-if="dataInfo.openLeave == 1">
          <a-button
            type="primary"
            danger
            class="leave red"
            v-if="dataInfo.isLeave <= 0"
          >
            <template #icon><i class="icon-qj"></i></template>
            {{ $t("train.ask_for_leave") }}
            <!-- 请假 -->
          </a-button>
          <a-button
            type="primary"
            danger
            class="leave hui"
            disabled
            v-else-if="dataInfo.isLeave == 2"
          >
            {{ $t("train.approving_for_leave") }}
            <!-- 请假审批中 -->
          </a-button>
          <a-button
            type="primary"
            danger
            class="leave white"
            v-else-if="dataInfo.isLeave == 1"
          >
            <template #icon><i class="icon-qj"></i></template>
            {{ $t("train.cancel_leave") }}
            <!-- 取消请假 -->
          </a-button>
        </template>
        <p>
          <a-rate v-model:value="dataInfo.avgSecScore" allow-half disabled /><em
            >{{ dataInfo.avgSecScore.toFixed(1) }}
            {{ $t("LB_Evaluate_Score") }}</em
          >
        </p>
        <p class="duration">
          <span class="span"
            >{{ $t("train.lesson_hours") }}：{{ dataInfo.courseTimes }}</span
          >
          <!-- 课时： -->
          <span
            class="span"
            v-if="dataInfo.trainLecturers && dataInfo.trainLecturers.length"
          >
            <!-- 讲师： -->
            {{ $t("XB_Course_Lecturer") }}：<template
              v-for="(item, index) in dataInfo.trainLecturers"
              :key="index"
            >
              <OpenData
                type="userName"
                :openid="item.lecturerName"
                v-if="item.lecturerType == 1"
              /><span v-else>{{ item.lecturerName }}</span
              >{{ index + 1 == dataInfo.trainLecturers.length ? "" : "、" }}
            </template>
          </span>
          <span class="span" v-else>
            <!-- 讲师： -->
            {{ $t("XB_Course_Lecturer") }}：<OpenData
              type="userName"
              :openid="dataInfo.LecturerName"
              v-if="dataInfo.lecturerType == 1"
            /><span v-else>{{ dataInfo.LecturerName }}</span>
          </span>
        </p>
        <div class="box">
          <p class="time">
            <ClockCircleOutlined />
            {{ moment(dataInfo.trainTime[0]).format("YYYY-MM-DD HH:mm") }} ~
            {{ moment(dataInfo.trainTime[1]).format("YYYY-MM-DD HH:mm") }}
          </p>
          <p class="address">
            {{ $t("train.class_place") }}：{{ dataInfo.address }}
          </p>
          <!-- 上课地点 -->
        </div>
      </div>
    </div>
  </section>

  <section class="mian" v-if="Object.keys(dataInfo).length > 0">
    <a-spin :spinning="loadding">
      <div class="detail">
        <a-tabs>
          <!-- 课程详情 -->
          <a-tab-pane key="1" :tab="$t('train.course_detail')">
            <div class="content" style="min-height: 500px">
              <div class="report" v-if="dataInfo.examUid > 0">
                <p>
                  <em>{{ $t("train.exam_results") }}：</em>
                  <!-- 考试成绩： -->
                  <span class="score">{{ dataInfo.examScores }}</span>
                  <span class="fen">{{ $t("CM_Points") }}</span>
                </p>
                <p v-if="dataInfo.examRemark != ''">
                  <span :class="['remark', { omit: !readMore }]">
                    <em>{{ $t("train.teacher_comment") }}：</em
                    >{{ dataInfo.examRemark }}
                    <!-- 老师评语： -->
                  </span>
                  <span class="more" @click="readMore = !readMore">{{
                    !readMore ? $t("train.view_full") : $t("CM_Retract")
                  }}</span>
                  <!-- "查看完整" : "收起" -->
                </p>
              </div>
              <div v-if="dataInfo.intro" v-html="dataInfo.intro"></div>
              <template
                v-if="
                  dataInfo.attachmentList && dataInfo.attachmentList.length > 0
                "
              >
                <p class="mark">
                  <em class="line"></em> {{ $t("CM_LB_AttachmentFile") }}
                </p>
                <!-- 附件 -->
                <div class="info-file">
                  <ul
                    v-for="item in dataInfo.attachmentList"
                    :key="item.documentId"
                  >
                    <li @click="preview(item)">
                      <PaperClipOutlined style="font-size: 18px" />
                      <span class="title">
                        <a-tooltip>
                          <template #title>
                            <template v-if="item.secretData">
                              {{ htsySecret[item.secretData] }}
                            </template>
                            {{ item.fileName }}
                          </template>
                          <template v-if="item.secretData">
                            {{ htsySecret[item.secretData] }}
                          </template>
                          {{ item.fileName }}
                        </a-tooltip>
                      </span>
                      （{{ getFileSize(item.fileSize) }}）
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </a-tab-pane>
          <!-- 活动附件 -->
          <a-tab-pane key="2" :tab="$t('train.activity_attachments')">
            <div class="activity content">
              <template
                v-if="
                  dataInfo.openUpload > 0 &&
                  dataInfo.trainAttachmentList &&
                  dataInfo.trainAttachmentList.length > 0
                "
              >
                <template v-if="files.image.length">
                  <p class="mark">
                    <em class="line"></em> {{ $t("train.activity_photos") }}
                  </p>
                  <!-- 活动图片 -->
                  <ul class="v-list">
                    <template
                      v-for="item in files.image"
                      :key="item.documentId"
                    >
                      <li>
                        <a-image class="img" :src="item.filePath" />
                      </li>
                    </template>
                  </ul>
                </template>
                <template v-if="files.other.length">
                  <p class="mark">
                    <em class="line"></em>
                    {{ $t("train.activity_attachments") }}
                  </p>
                  <!-- 活动附件 -->
                  <div class="other-file">
                    <ul>
                      <li v-for="item in files.other" :key="item.documentId">
                        <a-tooltip placement="bottom">
                          <template #title>
                            <template v-if="item.secretData">
                              {{ htsySecret[item.secretData] }}
                            </template>
                            {{ item.fileName + "." + item.suffix }}
                          </template>
                          <a href="#javascript;;" @click="viewOrDownFile(item)">
                            <img
                              v-if="item.fileType == 1"
                              src="../../assets/image/train/icon_shipin.png"
                            />
                            <img
                              v-if="item.fileType == 2"
                              src="../../assets/image/train/icon_yinpin.png"
                            />
                            <img
                              v-if="item.fileType == 3"
                              src="../../assets/image/train/icon_wendang.png"
                            />
                            <img
                              v-if="item.fileType == 6"
                              src="../../assets/image/train/icon_h5.png"
                            />
                            <span class="title">
                              <template v-if="item.secretData">
                                {{ htsySecret[item.secretData] }}
                              </template>
                              {{ item.fileName }}
                            </span>
                          </a>
                        </a-tooltip>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
              <a-empty
                v-else
                :description="$t('no_attachment')"
                style="padding: 100px 0"
              />
              <!-- 暂无附件 -->
            </div>
          </a-tab-pane>
          <!-- 课程讨论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('train.course_comment')"
            forceRender
          >
            <div class="content">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
              ></my-comment>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
    <div class="cards">
      <div class="item card-1">
        <i class="card-top"></i>
        <h2 class="title">{{ $t("train.attendance") }}</h2>
        <!-- 考勤 -->
        <div
          class="sign"
          v-if="
            dataInfo.inStartTime != '' &&
            dataInfo.inEndTime != '' &&
            dataInfo.inStartTime != '1970-01-01 08:00:00' &&
            dataInfo.inEndTime != '1970-01-01 08:00:00'
          "
        >
          <p class="tit">
            <span class="label">{{ $t("train.sign_in_time") }}：</span>
            <!-- 签到时间： -->
            <span class="green" v-if="dataInfo.inTime > 0">{{
              $t("Pub_Btn_AlreadySign")
            }}</span>
            <!-- 已签到 -->
            <span v-else>{{ $t("train.no_sign") }}</span>
            <!-- 未签到 -->
          </p>
          <p class="time">
            {{ dateFormat(dataInfo.inStartTime) }} -
            {{ dateFormat(dataInfo.inEndTime) }}
          </p>
        </div>
        <div
          class="sign"
          v-if="
            dataInfo.outStartTime != '' &&
            dataInfo.outEndTime != '' &&
            dataInfo.outStartTime != '1970-01-01 08:00:00' &&
            dataInfo.outEndTime != '1970-01-01 08:00:00'
          "
        >
          <p class="tit">
            <span class="label">{{ $t("train.sign_out_time") }}：</span>
            <!-- 签退时间： -->
            <span class="green" v-if="dataInfo.outTime > 0">{{
              $t("train.signed_out")
            }}</span>
            <!-- 已签退 -->
            <span v-else>{{ $t("train.no_sign_out") }}</span>
            <!-- 未签退 -->
          </p>
          <p class="time">
            {{ dateFormat(dataInfo.outStartTime) }} -
            {{ dateFormat(dataInfo.outEndTime) }}
          </p>
        </div>
        <template v-if="isShowMeet && dataInfo.trainType > 1">
          <a-button
            type="primary"
            ghost
            v-if="platformConfig.platform == 26"
            @click="goWxMeet(dataInfo.meetId)"
            >{{ $t("train.enter_meeting") }}</a-button
          >
          <!-- 进入会议 -->
          <a-button
            type="primary"
            ghost
            v-if="
              platformConfig.platform == 31 || platformConfig.platform == 30
            "
            @click="goDingMeet(dataInfo.meetData)"
            >{{ $t("train.enter_meeting") }}</a-button
          >
          <!-- 进入会议 -->
        </template>
      </div>
      <div class="item card-2" v-if="dataInfo.lesId > 0">
        <i class="card-top"></i>
        <h2 class="title">{{ $t("train.lecturer_rating") }}</h2>
        <!-- 讲师评分 -->
        <p class="score">
          <template v-if="dataInfo.lesTime > 0">
            {{ dataInfo.lesScores.toFixed(1)
            }}<span>{{ $t("CM_Points") }}</span>
          </template>
          <span class="hui" v-else>{{ $t("train.not_rated") }}</span>
          <!-- 未评分 -->
        </p>
      </div>
      <div class="item card-3" v-if="dataInfo.selId > 0">
        <i class="card-top"></i>
        <h2 class="title">{{ $t("train.evaluation_lecturer") }}</h2>
        <!-- 评价讲师 -->
        <p class="score">
          <template v-if="dataInfo.selTime > 0">
            {{ dataInfo.selScores.toFixed(1)
            }}<span>{{ $t("CM_Points") }}</span>
          </template>
          <span class="hui" v-else>{{ $t("train.not_rated") }}</span>
          <!-- 未评分 -->
        </p>
        <a-button
          type="primary"
          ghost
          v-if="dataInfo.selTime <= 0"
          @click="openQuestionnaire(dataInfo.selId, 2)"
          >{{ $t("train.evaluation_lecturer") }}</a-button
        >
        <!-- 评价讲师 -->
        <a-button
          type="primary"
          ghost
          v-else
          @click="openQuestionnaire(dataInfo.selId, 2, 100)"
          >{{ $t("train.view_reviews") }}</a-button
        >
        <!-- 查看评价 -->
      </div>
      <div class="item card-4" v-if="dataInfo.secId > 0">
        <i class="card-top"></i>
        <h2 class="title">{{ $t("train.evaluation_course") }}</h2>
        <!-- 评价课程 -->
        <p class="score">
          <template v-if="dataInfo.secTime > 0">
            {{ dataInfo.secScores.toFixed(1)
            }}<span>{{ $t("CM_Points") }}</span>
          </template>
          <span class="hui" v-else>{{ $t("train.not_rated") }}</span>
          <!-- 未评分 -->
        </p>
        <a-button
          type="primary"
          ghost
          v-if="dataInfo.secTime <= 0"
          @click="openQuestionnaire(dataInfo.secId, 3)"
          >{{ $t("train.evaluation_course") }}</a-button
        >
        <!-- 评价课程 -->
        <a-button
          type="primary"
          ghost
          v-else
          @click="openQuestionnaire(dataInfo.secId, 3, 100)"
          >{{ $t("train.view_reviews") }}</a-button
        >
        <!-- 查看评价 -->
      </div>
    </div>
  </section>
  <a-modal
    v-model:visible="videoVisible"
    :title="videoTiele"
    width="1000px"
    @ok="handleCancelVideo"
    @cancel="handleCancelVideo"
    destroyOnClose
  >
    <video
      :src="videoSrc"
      autoplay
      controls
      style="width: 100%; height: 60vh"
    ></video>
  </a-modal>
  <!-- 评价 -->
  <a-modal
    v-model:visible="questionnaireVisible"
    :title="$t('train.evaluation')"
    width="1050px"
    :footer="null"
    @ok="questionnaireSubmit()"
    :maskClosable="false"
    :keyboard="false"
    destroyOnClose
    :bodyStyle="{ overflow: 'auto', height: '70vh', padding: '0' }"
  >
    <questionnaire
      ref="questionnaireRef"
      :taskId="taskId"
      :did="did"
      :rid="rid"
      :evalType="evalType"
      :progress="progress"
      source="train"
    />
  </a-modal>
  <docPreview
    :isVisible="previewVisible"
    :documentId="fileDocumentId"
    :fileStatus="fileStatus"
    :fileType="fileType"
    :filePath="filePath"
    :fileName="fileName"
    :fileHome="fileHome"
    @previewEnd="
      (e) => {
        previewVisible = e;
      }
    "
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  ref,
  getCurrentInstance,
  reactive,
  toRefs,
  watch,
  computed,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { dateFormat, getFileSize, viewOrDownFile } from "@/utils/tools";
import { imgRegex } from "@/utils/formRules";
import { trainDetail } from "@/api/train";
import myComment from "@/components/my-comment";
import moment from "moment";
import questionnaire from "@/views/questionnaire/detail2";
import { htsySecret } from "@/utils/business";
import docPreview from "@/components/preview/index.vue";
import OpenData from "@/components/OpenData.vue";
import wxwork from "@/utils/wxwork";
export default {
  components: {
    "my-comment": myComment,
    questionnaire,
    docPreview,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = Number(route.query.id);
    const did = Number(route.query.did);

    const state = reactive({
      files: {
        image: [],
        other: [],
      },
      rateValue: 0,
      readMore: false,
      topImg: require("@/assets/image/train/top-bg.png"),
      videoVisible: false,
      videoTiele: "",
      videoSrc: "",
      questionnaireVisible: false, //问卷
      rid: 0, //评估表id
      evalType: 0, //评估类型 0任务使用 2学员评价老师 3学员评价课程
      progress: 0,
      hasComment: false,
      previewVisible: false,
      fileStatus: 1,
      fileDocumentId: 0,
      fileType: "",
      filePath: "",
      fileName: "",
      fileHome: "",
      isShowMeet: true,
    });

    const platformConfig = computed(() => store.getters.platformConfig);

    watch(
      () => store.getters.companyInfo,
      (company) => {
        state.hasComment = company.useComment == 1;
      },
      { immediate: true }
    );

    let loadding = ref(true),
      dataInfo = ref({}),
      commentParams = ref({
        page: 1,
        pageSize: 10,
        resourceId: did || 0,
        type: 4, //0知识 1课程 2任务 3新闻 4面授
        Total: 0,
      });
    trainDetail(taskId, did).then((res) => {
      loadding.value = false;
      if (res.ret === 0) {
        // 讲师
        if (res.data.trainLecturers && res.data.trainLecturers.length) {
          res.data.lecturerUid = res.data.trainLecturers[0].lecturerUid;
          res.data.LecturerName = res.data.trainLecturers[0].lecturerName;
          res.data.lecturerType = res.data.trainLecturers[0].lecturerType;
          res.data.lecturerAvatar = res.data.trainLecturers[0].lecturerAvatar;
        }
        let now = new Date().getTime();
        let endTime = new Date(
          res.data.trainTime[1].replace(/-/g, "/")
        ).getTime();
        state.isShowMeet = now < endTime;

        dataInfo.value = res.data;
        // 活动附件各类型总数
        dataInfo.value.trainAttachmentType = [0, 0];
        if (
          dataInfo.value.trainAttachmentList &&
          dataInfo.value.trainAttachmentList.length > 0
        ) {
          allRender(res.data.trainAttachmentList);
        }

        if (wxwork.isWxWork) {
          wxwork.agentConfig();
        }
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: res.msg,
            path: "/",
            name: $t("CM_Index"),
          },
        });
      }
    });

    const allRender = (list) => {
      list.map((item) => {
        if (imgRegex.test(item.filePath)) {
          state.files.image.push(item);
        } else {
          state.files.other.push(item);
        }
      });
    };

    const handleCancelVideo = () => {
      state.videoVisible = false;
    };

    const questionnaireRef = ref(null);
    const questionnaireSubmit = () => {
      if (state.progress == 100) {
        state.questionnaireVisible = false;
        return false;
      }
      questionnaireRef.value.submit();
    };

    const getCourseTime = (minutes) => {
      let courseTimesH = parseInt(minutes / 60);
      let courseTimesM = minutes - parseInt(minutes / 60) * 60;
      if (courseTimesH > 0) {
        if (courseTimesM > 0) {
          return courseTimesH + $t("Pub_Hour") + courseTimesM + $t("CM_Minute");
        }
        return courseTimesH + $t("Pub_Hour");
      }
      return courseTimesM + $t("CM_Minute");
    };

    const openQuestionnaire = (rid, evalType, progress) => {
      state.rid = rid;
      state.evalType = evalType;
      state.progress = progress || 0;
      state.questionnaireVisible = true;
    };

    const preview = (data) => {
      state.previewVisible = true;
      state.fileStatus = data.status;
      state.fileDocumentId = data.documentId || 0;
      state.fileType = data.fileType;
      state.filePath = data.filePath;
      state.fileName = data.fileName || "";
      state.fileHome = data.home || "";
    };

    const goWxMeet = (meetId) => {
      if (wxwork.isWxWork) {
        wxwork.startMeeting(meetId);
      } else {
        proxy.$message.error(
          $t("train.please_use_enterprise_wechat_to_participate_in_meeting")
        );
        // 请用企业微信参与会议
      }
    };

    const goDingMeet = (meetData) => {
      proxy.$message.info($t("train.is_open_dingding_meeting"));
      // 正在打开钉钉会议...
      window.open(meetData.url);
    };

    return {
      ...toRefs(state),
      dateFormat,
      getFileSize,
      viewOrDownFile,
      loadding,
      dataInfo,
      commentParams,
      moment,
      getCourseTime,
      handleCancelVideo,
      openQuestionnaire,
      questionnaireSubmit,
      questionnaireRef,
      preview,
      htsySecret,
      goWxMeet,
      goDingMeet,
      platformConfig,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding: 42px 0 100px;
  position: relative;
  .bg {
    .absolute-full();
    z-index: 1;
    background-size: cover;
    background-position: center;
    z-index: 1;
    overflow: hidden;
    min-height: 392px;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: -50px;
    //   right: -50px;
    //   bottom: -50px;
    //   left: -50px;
    //   background: inherit;
    //   filter: blur(30px);
    //   z-index: 2;
    // }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: -50px;
    //   right: -50px;
    //   bottom: -50px;
    //   left: -50px;
    //   background-color: rgba(0, 0, 0, 0.2);
    //   z-index: 3;
    // }
  }
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .cover {
      z-index: 4;
      .mixinImgWrap(450px; 250px);
      border-radius: 6px;
    }
    .text {
      z-index: 4;
      width: calc(100% - 488px);
      color: #fff;
      line-height: 1.5;
      position: relative;
      h1 {
        width: calc(100% - 180px);
        font-size: 32px;
        font-weight: bold;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 12px;
        word-break: break-all;
      }
      .leave {
        .icon-qj {
          display: inline-block;
          background-image: url(../../assets/image/icon-qj.png);
          width: 27px;
          height: 27px;
          vertical-align: -6px;
          margin-right: 8px;
        }
        &.red {
          background: #e63450;
          border-color: #e63450;
          letter-spacing: 2px;
        }
        &.hui {
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        &.white {
          background: #bec8c8;
          border-color: #bec8c8;
        }
        width: 160px;
        height: 44px;
        line-height: 32px;
        border-radius: 4px;
        font-size: 20px;
        position: absolute;
        right: 0;
        top: 0;
      }
      p {
        margin-bottom: 12px;
        font-size: 20px;
        em {
          margin-left: 20px;
          vertical-align: -1px;
        }
        &.duration {
          color: #f5f5f5;
          font-size: 16px;
          .span:not(last-child) {
            margin-right: 20px;
          }
        }
      }
      .box {
        font-size: 18px;
        margin-top: 40px;
        height: 80px;
        .time {
          font-size: 24px;
        }
        .address {
          font-size: 16px;
          color: #f5f5f5;
        }
      }
    }
  }
}

.mian {
  position: relative;
  z-index: 1;
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .detail {
    width: 890px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: -56px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    .content {
      padding: 14px 30px 10px 30px;
      .mark {
        font-size: 15px;
        font-weight: bold;
        margin: 30px 0;
        .line {
          width: 5px;
          height: 16px;
          display: inline-block;
          background: @color-theme;
          vertical-align: -2px;
        }
      }
      .info-file {
        ul {
          li {
            padding: 8px 15px;
            background: #f1f1f1;
            margin-bottom: 15px;
            color: #8b8b8b;
            width: 50%;
            border-radius: 6px;
            display: flex;
            justify-content: left;
            align-items: center;
            cursor: pointer;
            &:hover {
              color: @color-theme;
            }
            .title {
              margin-left: 10px;
              .mixinEllipsis(15px);
            }
          }
        }
      }
      .other-file {
        ul {
          li {
            width: 72px;
            margin-bottom: 15px;
            margin-right: 30px;
            border-radius: 6px;
            display: inline-flex;
            flex-direction: column;
            text-align: center;
            cursor: pointer;
            font-size: 10px;
            img {
              margin-bottom: 12px;
            }
            &:hover {
              color: @color-theme;
            }
            .title {
              .mixinEllipsis(34px,2);
            }
          }
        }
      }
    }
    .report {
      width: 100%;
      background: #f5f5f6;
      margin-bottom: 30px;
      padding: 20px;
      p:nth-child(1) {
        margin: 0;
      }
      p:nth-child(2) {
        margin: 20px 0 0;
      }
      em {
        margin-right: 16px;
        font-size: 15px;
        font-weight: 600;
      }
      .score {
        color: @color-theme;
        font-weight: 500;
        font-size: 17px;
      }
      .fen {
        color: @color-theme;
        font-size: 15px;
      }
      .remark {
        display: inline;
        &.omit {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 89%;
          float: left;
        }
      }
      .more {
        margin-left: 25px;
        color: @color-theme;
        cursor: pointer;
      }
    }
    .activity {
      padding-top: 0px;
      margin-top: -10px;
      min-height: 500px;
      .v-list {
        display: inline-block;
        margin-bottom: -20px;
        li {
          position: relative;
          float: left;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            .title {
              color: @color-theme;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          // ::v-deep(.img){
          //   width: 262px;
          //   height: 172px;
          //   margin-bottom: 5px;
          // }
          ::v-deep(.ant-image) {
            width: 262px;
            height: 172px;
            margin-bottom: 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              cursor: pointer;
            }
          }
          video {
            width: 262px;
            height: 148px;
          }
          .mask {
            position: absolute;
            width: 262px;
            height: 148px;
            background: #0000005c;
            line-height: 196px;
            text-align: center;
            .icon-play {
              display: inline-block;
              width: 60px;
              height: 60px;
              background-size: contain;
              background-image: url(../../assets/image/play.png);
            }
          }
        }
      }
    }
  }
  .cards {
    width: 290px;
    height: 100%;
    margin-top: -56px;
    .item {
      padding: 20px 24px;
      border-radius: 6px;
      margin-bottom: 24px;
      color: #333333;
      .card-top {
        width: 33px;
        height: 22px;
        background: url(../../assets/image/train/card-top.png);
        display: inline-block;
        margin-bottom: 8px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .sign {
        padding-top: 8px;
        font-size: 16px;
        font-weight: 600;
        p {
          margin: 14px 0;
          &.time {
            font-size: 14px;
            font-weight: initial;
            color: #666666;
            .mixinFlex(space-between; center);
            span {
              &.green {
                color: #1ab700;
              }
            }
          }
        }
      }
      .score {
        font-size: 50px;
        font-weight: 600;
        color: @color-theme;
        margin: 0;
        span {
          margin-left: 5px;
          font-size: 36px;
          font-weight: initial;
          &.hui {
            color: #aaaaaa;
          }
        }
      }
      ::v-deep(.ant-btn) {
        width: 100%;
        height: 60px;
        border-radius: 6px;
        font-size: 21px;
        margin-top: 10px;
        font-weight: bold;
        color: @color-theme;
        border-width: 2px;
      }
      &.card-1 {
        width: 282px;
        background: url(../../assets/image/train/card-bg1.png);
        background-repeat: no-repeat;
        background-color: #fff;
        padding: 20px 18px 6px;
      }
      &.card-2 {
        width: 282px;
        height: 169px;
        background: url(../../assets/image/train/card-bg2.png);
        background-repeat: no-repeat;
        background-color: #fff;
        .title {
          margin: 0;
        }
      }
      &.card-3 {
        width: 282px;
        height: 263px;
        background: url(../../assets/image/train/card-bg3.png);
        background-repeat: no-repeat;
        background-color: #fff;
        .score {
          span {
            margin-left: 0;
          }
        }
      }
      &.card-4 {
        width: 282px;
        height: 263px;
        background: url(../../assets/image/train/card-bg4.png);
        background-repeat: no-repeat;
        background-color: #fff;
      }
    }
  }
}
</style>
